import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'js-cookie';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    cart: {
      products: Cookies.get('products-new') && localStorage.getItem('products-new') ? JSON.parse(localStorage.getItem('products-new')) : [],
    },
  },
  getters: {
    products: (state) => {
      return state.cart.products.sort((a, b) => {
        if (a.idx > b.idx) {
          return 1;
        }
        if (a.idx < b.idx) {
          return -1;
        }
        return 0;
      });
    },
    productsTotalCount: (state) => {
      let sum = 0;
      state.cart.products.forEach((product) => {
        sum += product.count;
      });

      return sum;
    },
    totalSum(state) {
      let sum = 0.0;

      state.cart.products.forEach((product) => {
        if (product.data[3]) {
          sum += product.count * parseFloat(product.data[3]);
        }
      });

      return sum.toFixed(2);
    },
  },
  actions: {
    addToCart({ commit }, data) {
      commit('ADD_TO_CART', data);
    },
    removeToCart({ commit }, data) {
      commit('REMOVE_TO_CART', data);
    },
    changeCountProduct({ commit }, data) {
      commit('CHANGE_COUNT_PRODUCT', data);
    },
  },
  mutations: {
    ADD_TO_CART(state, data) {
      const id = state.cart.products.findIndex((product) => product.idx === data.idx);
      if (id !== -1) {
        if (state.cart.products[id].count + 1 <= state.cart.products[id].countMax) {
          state.cart.products[id].count += 1;
          Vue.notify({
            group: 'cart',
            type: 'success',
            title: 'Товар добавлен в корзину',
            text: `Артикул ${state.cart.products[id].data[0]}`,
          });
        } else {
          Vue.notify({
            group: 'cart',
            type: 'error',
            title: `В наличии только ${state.cart.products[id].countMax} шт.`,
            text: `Артикул ${state.cart.products[id].data[0]}`,
          });
        }
      } else {
        state.cart.products.push({
          idx: data.idx,
          count: 1,
          countMax: parseInt(data.data[2]),
          data: data.data,
        });
        Vue.notify({
          group: 'cart',
          type: 'success',
          title: 'Товар добавлен в корзину',
          text: `Артикул ${data.data[0]}`,
        });
      }
      Cookies.set('products-new', '1');
      localStorage.setItem('products-new', JSON.stringify(state.cart.products));
    },
    REMOVE_TO_CART(state, data) {
      const id = state.cart.products.findIndex((product) => product.idx === data.idx);
      if (id !== -1) {
        state.cart.products.splice(id, 1);
        Cookies.set('products-new', '1');
        localStorage.setItem('products-new', JSON.stringify(state.cart.products));
      }
    },
    CHANGE_COUNT_PRODUCT(state, data) {
      const id = state.cart.products.findIndex((product) => product.idx === data.product.idx);
      const value = parseInt(data.val);
      console.log(value)
      if (id !== -1) {
        if (value > 0) {
          if (value <= state.cart.products[id].countMax) {
            state.cart.products[id].count = 0;
            state.cart.products[id].count = value;
          } else {
            const oldVal = state.cart.products[id].count;
            state.cart.products[id].count = 0;
            state.cart.products[id].count = oldVal;
            Vue.notify({
              group: 'cart',
              type: 'error',
              title: `В наличии только ${state.cart.products[id].countMax} шт.`,
              text: `Артикул ${state.cart.products[id].data[0]}`,
            });
          }
        }
      }
      Cookies.set('products-new', '1');
      localStorage.setItem('products-new', JSON.stringify(state.cart.products));
    },
  },
});

export default store;
