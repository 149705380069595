const textError = {
  customPhone: 'Поле некорректно заполнено',
};

export default {
  customPhone: {
    validate: (value) => {
      let number = value;
      if (value.search(/[+()-\s]/g) !== -1) {
        number = value.split('(')[1].replace(/[+()-\s]/g, '');
      }
      return number.length === 10 ? true : textError.customPhone;
    },
  },
};
