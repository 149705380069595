<template>
  <div>
    <div class="cart">
      <div class="cart__body">
        <div class="icon-basket" />
        <div class="cart__counter">
          {{ productsTotalCount }}
        </div>
      </div>
      <notifications
        group="cart"
        position="top right"
      />
      <div
        v-if="products.length"
        class="cart__positions"
      >
        <div
          class="h1"
          style="margin-bottom: 15px; text-align: left; font-size:25px;"
        >
          Корзина
        </div>
        <div class="cart__head">
          <div>Артикул</div>
          <div>Наименование</div>
          <div>Кол-во</div>
          <div>Цена</div>
          <div />
        </div>
        <div class="cart__positions-positions">
          <div
            v-for="(product) in products"
            :key="product.idx"
            class="cart__position"
          >
            <div>
              <div class="cart__positions-mobile-name">
                <b>Артикул</b>
              </div>
              <div>{{ product.data ? product.data[0] : '' }}</div>
            </div>
            <div>
              <div class="cart__positions-mobile-name">
                <b>Наименование</b>
              </div>
              <div :title="product.data ? product.data[1] : ''">
                {{ product.data ? product.data[1] : '' }}
              </div>
            </div>
            <div>
              <div class="cart__positions-mobile-name">
                <b>Кол-во</b>
              </div>
              <!--              <div>{{ product.count }}</div>-->
              <div>
                <input
                  type="number"
                  :value="product.count"
                  min="1"
                  @input="changeCountProduct(product, $event.target.value)"
                  @blur="inputBlur(product, $event.target.value)"
                >
              </div>
            </div>
            <div>
              <div class="cart__positions-mobile-name">
                <b>Цена шт.</b>
              </div>
              <div>{{ product.data ? (product.count * parseFloat(product.data[3])).toFixed(2) : '' }}</div>
            </div>
            <div>
              <span
                class="cart__remove"
                @click="removeToCart(product)"
              >Удалить <span class="icon-trash" /></span>
            </div>
          </div>
        </div>
        <div
          class="h2"
          style="font-size:18px; margin-top: 15px; text-align: left"
        >
          Итого: {{ totalSum }}
        </div>
        <div style="margin-top: 15px;">
          <button
            class="btn btn--min"
            @click="showBuyModal"
          >
            Заказать
          </button>
        </div>
      </div>
    </div>
    <modal
      name="buy-modal"
      classes="buy-modal"
      :adaptive="true"
      height="auto"
    >
      <div>
        <div class="buy-modal__close" @click="closeBuyModal">
          ×
        </div>
        <div class="buy-modal__title h1">
          Форма заказа
        </div>
        <ValidationObserver
          ref="form"
        >
          <form @submit.prevent="submit">
            <div>
              <input-text
                v-model="form.name"
                label="Имя"
              />
            </div>
            <div>
              <input-text
                v-model="form.phone"
                label="Телефон"
                mask="+7 (###) ###-####"
                :rules="{ required: true, customPhone: true }"
              />
            </div>
            <div class="buy-modal__footer">
              <div class="buy-modal__total-block">
                <div>
                  <button
                    type="submit"
                    class="btn"
                    @click="showBuyModal"
                  >
                    Заказать
                  </button>
                </div>
                <div class="buy-modal__total">
                  Итого: {{ totalSum }}
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </modal>
    <transition name="fade">
      <preloader v-if="system.sending" />
    </transition>
  </div>
</template>

<script>
import inputText from '@/components/form/input';
import preloader from '@/components/preloader';
import { serialize } from 'object-to-formdata';
import recaptcha from "@/helpers/recaptcha";
import api from '@/helpers/api';

export default {
  name: 'Cart',
  components: {
    inputText,
    preloader,
  },
  mixins: [api, recaptcha],
  data() {
    return {
      form: {
        name: '',
        phone: '',
      },
      system: {
        sending: false,
      },
    };
  },
  computed: {
    products() {
      return this.$store.getters.products;
    },
    productsTotalCount() {
      return this.$store.getters.productsTotalCount;
    },
    totalSum() {
      return this.$store.getters.totalSum;
    },
  },
  methods: {
    removeToCart(product) {
      this.$store.dispatch('removeToCart', product);
      this.$notify({
        group: 'cart',
        type: 'success',
        title: 'Товар удален из корзины',
        text: `Артикул ${product.data[0]}`,
      });
    },
    changeCountProduct(product, value) {
      const val = parseInt(value);
      if (val !== NaN && val > 0) {
        this.$store.dispatch('changeCountProduct', {
          product,
          val,
        });
      }
    },
    inputBlur(product, value) {
      const val = parseInt(value) !== NaN && parseInt(value) > 0 ? parseInt(value) : 1;
      this.$store.dispatch('changeCountProduct', {
        product,
        val,
      });
    },
    showBuyModal() {
      this.$modal.show('buy-modal');
    },
    closeBuyModal() {
      this.$modal.hide('buy-modal');
    },
    submit() {
      this.$refs.form.validate()
        .then((success) => {
          if (!success) {
            return;
          }

          this.recaptcha()
            .then((token) => {
              const sendData = {
                ...this.form,
                products: this.products,
                totalSum: this.totalSum,
                token,
              };

              const formData = serialize(sendData, {
                indices: true,
              });

              this.system.sending = true;
              this.sendOrder(formData)
                .then((response) => {
                  console.log(response);
                  setTimeout(() => {
                    this.system.sending = false;
                    this.$notify({
                      group: 'cart',
                      type: 'success',
                      title: 'Заказ принят',
                      text: 'Ожидайте звонка от менеджера',
                    });
                    this.closeBuyModal();
                  }, 1000);
                })
                .catch((e) => {
                  console.log(e);
                  this.$notify({
                    group: 'cart',
                    type: 'error',
                    title: 'Ошибка сервера',
                    text: 'Повротите позже',
                  });
                  setTimeout(() => {
                    this.system.sending = false;
                  }, 1000);
                });
            })
            .catch((e) => {
              console.log(e);
              console.log('Не валидный токен рекапчи');
            });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cart {
  display: inline-block;
  &__body {
    position: relative;
  }
  &:hover {
    .cart__positions {
      visibility: visible;
      opacity: 1;
    }
  }

  &__remove {
    cursor: pointer;
    &:hover {
      color: #ff1d1d;
    }
  }

  &__counter {
    position: absolute;
    left: 100%;
    top: 0;
    color: #fff;
    background-color: #ff1d1d;
    line-height: 1;
    padding: 2px 5px;
    border-radius: 20px;
    font-size: 12px;
    transform: translateX(-10px);
  }
  &__head {
    display: flex;
    @media (max-width: 1024px) {
      display: none;
    }
    & > div {
      padding-right: 10px;
      &:nth-child(1) {
        width: 15%;
        text-align: left;
      }
      &:nth-child(2) {
        width: 40%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &:nth-child(3) {
        width: 15%;
        text-align: center;
      }
      &:nth-child(4) {
        width: 10%;
        text-align: right;
        align-items: flex-end;
      }
      &:nth-child(5) {
        width: 20%;
        text-align: right;
        align-items: flex-end;
      }
      &:last-child {
        padding-right: 0px;
      }
      @media (max-width: 1024px) {
        width: 100% !important;
      }
    }
  }
  &__positions {
    transition: .3s opacity;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    box-shadow: 0 5px 15px #1d1d1d40;
    padding: 20px;
    z-index: 5;
    max-width: 100%;
    &:after {
      content: ' ';
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
      height: 47px;
    }
  }
  &__positions-positions {
    &:hover {
      .cart__position {
        opacity: .6;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  &__positions-mobile-name {
    display: none;
    @media (max-width: 1024px) {
      display: block;
    }
  }
  &__position {
    display: flex;
    padding: 15px 0;
    border-bottom: 1px solid #e9e9e9;
    input {
      width: 100%;
    }
    &:hover {
      border-color: #ff1d1d;
    }
    @media (max-width: 1024px) {
      flex-direction: column;
    }
    & > div {
      padding-right: 10px;
      &:nth-child(1) {
        width: 15%;
        text-align: left;
      }
      &:nth-child(2) {
        width: 40%;
        div {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      &:nth-child(3) {
        width: 15%;
        text-align: center;
      }
      &:nth-child(4) {
        width: 10%;
        text-align: right;
        align-items: flex-end;
      }
      &:nth-child(5) {
        width: 20%;
        text-align: right;
        align-items: flex-end;
      }
      &:last-child {
        padding-right: 0px;
      }
      @media (max-width: 1024px) {
        width: 100% !important;
        text-align: left !important;
        margin: 10px 0;
      }
    }
  }
}
.buy-modal {
  padding: 30px;
}
.fade-enter-active {
  animation: show-list .5s;
}
.fade-leave-active {
  animation: show-list .5s reverse;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
