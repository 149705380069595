var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("catalog", {
    attrs: { items: _vm.catalogItems, "is-loaded": _vm.isLoaded }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }