var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "catalog" }, [
    _c("div", { staticClass: "catalog__search" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.search,
            expression: "search"
          }
        ],
        attrs: { type: "text", placeholder: "Поиск по названию или артикулу" },
        domProps: { value: _vm.search },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.search = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _vm.products.length && _vm.search
      ? _c(
          "div",
          { staticClass: "catalog__items" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "transition-group",
              {
                staticClass: "catalog__items-items",
                attrs: { name: "show-list", tag: "div" }
              },
              _vm._l(_vm.productsShow, function(product) {
                return _c(
                  "div",
                  { key: product.idx, staticClass: "catalog__item" },
                  [
                    _c("div", [
                      _c("div", { staticClass: "catalog__item-mobile-name" }, [
                        _vm._v("Артикул")
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s(product.data ? product.data[0] : ""))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("div", { staticClass: "catalog__item-mobile-name" }, [
                        _vm._v("Наименование")
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s(product.data ? product.data[1] : ""))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("div", { staticClass: "catalog__item-mobile-name" }, [
                        _vm._v("Остаток")
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s(product.data ? product.data[2] : ""))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("div", { staticClass: "catalog__item-mobile-name" }, [
                        _vm._v("Цена")
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s(product.data ? product.data[3] : ""))
                      ])
                    ]),
                    _vm._v(" "),
                    [
                      _vm.hideAdd(product)
                        ? _c("div", [
                            _c(
                              "span",
                              {
                                staticClass: "catalog__cart-button",
                                on: {
                                  click: function($event) {
                                    return _vm.addToCart(product)
                                  }
                                }
                              },
                              [
                                _vm._v("\n            Добавить\n            "),
                                _c("span", { staticClass: "icon-plus" })
                              ]
                            )
                          ])
                        : _c("div", [
                            _vm._v(
                              "\n            Вы добавили весь остаток (" +
                                _vm._s(product.data[2]) +
                                " шт)\n          "
                            )
                          ])
                    ]
                  ],
                  2
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.showMore
              ? _c("div", { staticClass: "catalog__more" }, [
                  _c(
                    "button",
                    { staticClass: "btn", on: { click: _vm.more } },
                    [_vm._v("Еще")]
                  )
                ])
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.products.length && _vm.isLoaded
      ? _c("div", { staticClass: "catalog__empty" }, [
          _vm._v("\n    Позиций не найдено\n  ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "catalog__head" }, [
      _c("div", [_vm._v("Артикул")]),
      _vm._v(" "),
      _c("div", [_vm._v("Наименование")]),
      _vm._v(" "),
      _c("div", [_vm._v("Остаток")]),
      _vm._v(" "),
      _c("div", [_vm._v("Цена")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }