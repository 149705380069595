<template>
  <div class="catalog">
    <div class="catalog__search">
      <input v-model="search" type="text" placeholder="Поиск по названию или артикулу">
    </div>
    <div v-if="products.length && search" class="catalog__items">
      <div class="catalog__head">
        <div>Артикул</div>
        <div>Наименование</div>
        <div>Остаток</div>
        <div>Цена</div>
      </div>
      <transition-group name="show-list" class="catalog__items-items" tag="div">
        <div v-for="(product) in productsShow" :key="product.idx" class="catalog__item">
          <div>
            <div class="catalog__item-mobile-name">Артикул</div>
            <div>{{ product.data ? product.data[0] : '' }}</div>
          </div>
          <div>
            <div class="catalog__item-mobile-name">Наименование</div>
            <div>{{ product.data ? product.data[1] : '' }}</div>
          </div>
          <div>
            <div class="catalog__item-mobile-name">Остаток</div>
            <div>{{ product.data ? product.data[2] : '' }}</div>
          </div>
          <div>
            <div class="catalog__item-mobile-name">Цена</div>
            <div>{{ product.data ? product.data[3] : '' }}</div>
          </div>
          <template>
            <div v-if="hideAdd(product)">
            <span class="catalog__cart-button" @click="addToCart(product)">
              Добавить
              <span class="icon-plus"></span>
            </span>
            </div>
            <div v-else>
              Вы добавили весь остаток ({{ product.data[2] }} шт)
            </div>
          </template>
        </div>
      </transition-group>
      <div v-if="showMore" class="catalog__more">
        <button class="btn" @click="more">Еще</button>
      </div>
    </div>
    <div v-if="!products.length && isLoaded" class="catalog__empty">
      Позиций не найдено
    </div>
  </div>
</template>

<script>
export default {
  name: 'Catalog',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isLoaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      per_page: 15,
      page: 1,
    };
  },
  computed: {
    showMore() {
      return this.totalPages > this.page;
    },
    totalPages() {
      return Math.ceil(this.products.length / this.per_page);
    },
    productsShow() {
      const newArr = [];

      for (let i = 0; i < this.per_page * this.page; i++) {
        if (this.products[i]) newArr.push(this.products[i]);
      }
      return newArr;
    },
    products() {
      const searchArr = this.search.split(' ');
      const arr = this.items.filter((item, index) => {
        if (item.data[1] === undefined) {
          console.log(item[1], index);
        }
        if (item.data[1]) {
          return searchArr.length === searchArr.filter(search => {
            return item.data[1].toLowerCase().includes(search.toLowerCase()) || item.data[0].toLowerCase().includes(search.toLowerCase());
          }).length;
        }
        return false;
      });
      return arr;
    },
  },
  watch: {
    search() {
      this.page = 1;
    },
  },
  methods: {
    more() {
      if (this.page < this.per_page) this.page++
    },
    addToCart(product) {
      this.$store.dispatch('addToCart', product);
    },
    hideAdd(product) {
      const item = this.$store.getters.products.find((item) => item.idx === product.idx);
      return item ? item.count < item.countMax : true;
    },
  },
}
</script>

<style lang="scss" scoped>
.show-list-enter-active {
  animation: show-list .5s;
}
.show-list-leave-active {
  animation: show-list .5s reverse;
}
@keyframes show-list {
  0% {
    opacity: 0;
    transform: rotateX(90deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}
.catalog {
  &__cart-button {
    cursor: pointer;
    &:hover {
      color: #ff1d1d;
    }
  }
  &__search {
    color: #000;
    margin-bottom: 15px;
    input {
      height: 40px;
      width: 100%;
    }
  }
  &__more {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  &__items-items {
    perspective: 1800px;
    &:hover {
      .catalog__item {
        opacity: .6;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  &__item {
    background-color: #fff;
    transition: .3s;
    display: flex;
    align-items: center;
    min-height: 50px;
    border-bottom: 1px solid #e9e9e9;
    padding: 15px 0;
    &:hover {
      border-color: #ff1d1d;
    }
    & > div {
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 50%;
      }
      &:nth-child(3) {
        width: 10%;
        text-align: center;
      }
      &:nth-child(4) {
        width: 10%;
        text-align: right;
        align-items: flex-start;
      }
      &:nth-child(5) {
        width: 15%;
        text-align: right;
        align-items: flex-start;
      }
    }
    @media (max-width: 1024px) {
      flex-direction: column;
      & > div {
        width: 100% !important;
        text-align: left !important;
        margin: 10px 0;
      }
    }
  }
  &__item-mobile-name {
    display: none;
    @media (max-width: 1024px) {
      display: block;
    }
  }
  &__head {
    font-size: 20px;
    height: 40px;
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
      display: none;
    }
    & > div {
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 50%;
      }
      &:nth-child(3) {
        width: 10%;
        text-align: center;
      }
      &:nth-child(4) {
        width: 10%;
        text-align: right;
      }
    }
  }
  &__empty {
    font-size: 20px;
    height: 40px;
  }
}
</style>
