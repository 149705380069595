<template>
  <ValidationProvider
    tag="div"
    class="v-input"
    v-slot:default="{ errors, failed, failedRules }"
    :rules="rules"
    :name="label"
  >
    <div class="v-input__label">
      {{ label }}
    </div>
    <div>
      <input
        type="text"
        :value="value"
        v-mask="mask || noMask"
        @input="$emit('input', $event.target.value)"
      >
      <span
        v-if="failed && !failedRules.excluded"
        class="v-input__error"
      >{{ errors[0] }}</span>
      <span
        v-if="failed && failedRules.excluded"
        class="v-input__error"
      >Нужно ввести уникальное значение.</span>
    </div>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'Input',
  props: {
    rules: {
      type: [Object, String],
      default: 'required',
    },
    label: {
      type: String,
      default: 'Заголовок',
    },
    value: {
      type: null,
    },
    mask: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      noMask: {
        mask: '*'.repeat(255),
        tokens: {
          '*': { pattern: /./ },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.v-input {
  text-align: left;
  margin-bottom: 15px;
  input {
    height: 40px;
    width: 100%;
    padding: 0 15px;
    border: 1px solid #000;
  }
  &__label {
    font-size: 16px;
  }
  &__error {
    color: #ff1d1d;
  }
}
</style>
