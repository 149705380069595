var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    staticClass: "v-input",
    attrs: { tag: "div", rules: _vm.rules, name: _vm.label },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          var failed = ref.failed
          var failedRules = ref.failedRules
          return [
            _c("div", { staticClass: "v-input__label" }, [
              _vm._v("\n    " + _vm._s(_vm.label) + "\n  ")
            ]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: _vm.mask || _vm.noMask,
                    expression: "mask || noMask"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.value },
                on: {
                  input: function($event) {
                    return _vm.$emit("input", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              failed && !failedRules.excluded
                ? _c("span", { staticClass: "v-input__error" }, [
                    _vm._v(_vm._s(errors[0]))
                  ])
                : _vm._e(),
              _vm._v(" "),
              failed && failedRules.excluded
                ? _c("span", { staticClass: "v-input__error" }, [
                    _vm._v("Нужно ввести уникальное значение.")
                  ])
                : _vm._e()
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }