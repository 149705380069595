import axios from 'axios';

export default {
  data() {
    return {
      CancelTokens: {},
    };
  },
  methods: {
    testAPI() {
      return axios.get(`${process.env.API_URL_TEST}/users`);
    },
    fetchCatalog() {
      return axios.get(`/documents/catalog.csv`, {
        responseType: 'arraybuffer',
        responseEncoding: 'binary',
      });
    },
    sendOrder(data) {
      return axios.post('/backend/send/index.php', data);
    },
  },
};
