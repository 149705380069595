import Vue from 'vue';
import VModal from 'vue-js-modal';
import Notifications from 'vue-notification';
import store from "@/store";
import App from "@/app";
import Cart from "@/cart";
import VueTheMask from 'vue-the-mask';

// validate
import {
  configure, setInteractionMode, ValidationObserver, ValidationProvider, extend, localize,
} from 'vee-validate';
import ru from 'vee-validate/dist/locale/ru.json';
import * as rules from 'vee-validate/dist/rules';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import customRules from './helpers/custom-rules';

Vue.use(VueReCaptcha, { siteKey: '6Ld5DpEaAAAAAD-oKLLwQ06f2GbXTprj3wiQI0vt' });

setInteractionMode('eager');
// install rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
for (const key in customRules) {
  extend(key, customRules[key]);
}
localize('ru', ru);
// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
// Classes
configure({
  classes: {
    valid: 'valid',
    invalid: 'error',
    events: 'input',
  },
});

Vue.use(Notifications);
Vue.use(VModal);
Vue.use(VueTheMask);

new Vue({
  store,
  render(h) { return h(App); },
}).$mount('#vue-test');

new Vue({
  store,
  render(h) { return h(Cart); },
}).$mount('#cart');